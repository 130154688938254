/**
 * @copyright KARD
 */

import React from 'react'

export default Image = ( props ) =>
{
    return (
        <>
        {
            props.src
        ?   <img
                className={props.cn}
                src={props.src}
                alt={props.altTxt}
                style={props.style}
            />

        :   <img
                className={props.cn}
                src={props.alt}
                alt={props.altTxt}
                style={props.style}
            />
        }
        </>
    )
}   // Image

